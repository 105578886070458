import api from './api';
import TokenService from './token';

const register = async (data: FormData) => {
  const res = await api.post('/auth/register', data);
  const { access_token, refresh_token } = res.data;
  console.log(res.data);
  if (access_token) {
    TokenService.setAccessToken(access_token);
    TokenService.setRefreshToken(refresh_token);
  }
  return res.data;
};

const login = async (email: string, password: string) => {
  const res = await api.post('/auth/login', {
    email,
    password,
  });
  const { access_token, refresh_token } = res.data;
  if (access_token) {
    TokenService.setAccessToken(access_token);
    TokenService.setRefreshToken(refresh_token);
  }
  return res.data;
};

const logout = () => {
  TokenService.removeTokens();
};

const isLogged = () => {
  const access_token = TokenService.getAccessToken();
  return access_token ? true : false;
};

const forgotPassword = async (email: string) => {
  const res = await api.post('/auth/forgot', {
    email,
  });

  return res.data;
};

const resetPassword = async (email: string, code: string, password: string) => {
  const res = await api.post('/auth/reset_password', {
    email,
    code,
    password,
  });

  return res.data;
};

const Auth = {
  login,
  register,
  logout,
  isLogged,
  forgotPassword,
  resetPassword,
};
export default Auth;
