import React from 'react';
import { Box, Center, Icon, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { BsFillPlusCircleFill, BsListUl, BsInfo, BsFillPersonFill, BsQuestionCircleFill } from 'react-icons/bs';
import { IconType } from 'react-icons/lib';
import { NavLink } from 'react-router-dom';

const BottomMenu = () => {
  return (
    <Box sx={{ position: 'sticky', bottom: '0', h: 120, bg: '#ffffff', borderTop: '1px solid #114e64' }}>
      <SimpleGrid columns={5} spacing="1px">
        <MenuItem icon={BsFillPlusCircleFill} to="/newticket">
          Nuova
        </MenuItem>
        <MenuItem icon={BsListUl} to="/all">
          Storico
        </MenuItem>
        <MenuItem icon={BsInfo} to="/news">
          News e Avvisi
        </MenuItem>
        <MenuItem icon={BsQuestionCircleFill} to="/faqs">
          F.A.Q.
        </MenuItem>
        <MenuItem icon={BsFillPersonFill} to="/profile">
          Profilo
        </MenuItem>
      </SimpleGrid>
    </Box>
  );
};
interface IMenuItem {
  icon: IconType;
  to: string;
}
const MenuItem: React.FC<IMenuItem> = ({ icon, children, to }) => {
  return (
    <NavLink exact to={to}>
      <Center h="120px">
        <VStack>
          <Icon as={icon} w={8} h={8} color="#114e64" />
          <Text fontSize="11px" textAlign="center">
            {children}
          </Text>
        </VStack>
      </Center>
    </NavLink>
  );
};

export default BottomMenu;
