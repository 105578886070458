import api from './api';

const getNews = async () => {
  const res = await api.get('/api/news');

  return res.data;
};

const getById = async (id: string | number) => {
  const res = await api.get(`/api/news/${id}`);

  return res.data;
};

const getFile = async (fileId: string) => {
  const res = await api.get(`/api/getnewsfile?f=${fileId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });

  return res;
};

const News = {
  getNews,
  getById,
  getFile,
};

export default News;
