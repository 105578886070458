import React, { useEffect, useState } from 'react';
import { Box, Text, Link, Icon } from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
// import { INews, news } from '../mockdata';
import News from '../services/news';
// import { formatDate } from '../utils';

interface INewsProps {
  id: string;
}
interface INewsAttachment {
  id: string;
  filename: string;
  url: string;
}
export interface INews {
  oggetto: string;
  testo: string;
  creato_il: Date;
  attachments?: INewsAttachment[];
}

const NewsComponent = () => {
  const [theNews, setTheNews] = useState<INews>();
  const [allegati, setAllegati] = useState<INewsAttachment[]>([]);
  const { id } = useParams<INewsProps>();

  // useEffect(() => {
  //   const idx = +id;
  //   if (news[idx]) {
  //     setTheNews(news[idx]);
  //   } else {
  //     setTheNews(undefined);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getNews = async () => {
    const n = await News.getById(id);

    const news: INews = n.news;
    if (n.allegati) {
      setAllegati(n.allegati);
    }

    news.creato_il = new Date(n.news.creato_il);
    setTheNews(news);
  };

  const getFile = async (id: string, filename: string) => {
    try {
      const res = await News.getFile(id);
      const type = res.headers['content-type'];

      const blob = new Blob([res.data], { type });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.log(error);
      alert('error');
    }
  };

  return (
    <Box p="1rem">
      {theNews ? (
        <>
          <Box mb="2em">
            <Text color="#114e64" fontWeight="bold">
              DATI NEWS
            </Text>
          </Box>
          <Box mb="1rem">
            <Text fontWeight="bold">Oggetto:</Text>
            <Text>{theNews.oggetto}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Testo:</Text>
            <Text>{theNews.testo}</Text>
          </Box>
          <Box mt="0.5rem">
            <Text fontSize="12px" textAlign="right">
              {/* {formatDate(theNews.creato_il)} */}
              {theNews.creato_il.toDateString()}
            </Text>
          </Box>
          {allegati.length > 0 && (
            <>
              <Box>
                <Text fontWeight="bold">Allegati:</Text>
                {allegati.map((a, i) => (
                  <Link key={`${a.filename}-${i}`} isExternal color="teal.500" onClick={() => getFile(a.id, a.filename)}>
                    {a.filename} <Icon as={FiExternalLink} />
                  </Link>
                ))}
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <p>Non Esiste</p>
        </>
      )}
    </Box>
  );
};

export default NewsComponent;
