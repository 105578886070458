import { Box, Button, Input, Progress, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeadingTitle from '../components/HeadingTitle';
import SmallText from '../components/SmallText';
import Auth from '../services/auth';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [resetting, setResetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  let history = useHistory();

  const handleSubmit = async () => {
    setError('');
    setResetting(false);
    setLoading(true);
    const res = await Auth.forgotPassword(email);

    if (res.error) {
      setError(res.error);
    }
    if (res.message) {
      setResetting(true);
    }
    setLoading(false);
  };

  const handleReset = async () => {
    setError('');
    setLoading(true);
    const res = await Auth.resetPassword(email, code, password);

    if (res.error) {
      setError(res.error);
    }

    if (res.message) {
      setSuccess(true);
      setTimeout(() => {
        history.replace('/');
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <>
      <Box p="1rem">
        <HeadingTitle>Recupera password</HeadingTitle>
        <Stack mb={30}>
          <SmallText>Email</SmallText>
          <Input
            variant="flushed"
            placeholder="Inserisci email"
            color={resetting ? 'gray.200' : ''}
            disabled={resetting}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {!resetting && (
            <Button colorScheme="blue" disabled={email === ''} onClick={handleSubmit} isLoading={loading}>
              Reset password
            </Button>
          )}
          {resetting && (
            <>
              <SmallText>Inserisci il codice ricevuto via mail</SmallText>
              <Input
                variant="flushed"
                placeholder="Inserisci codice"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                // disabled={resetting}
              />
              <SmallText>Inserisci nuova password</SmallText>
              <Input
                variant="flushed"
                placeholder="Inserisci nuova password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <SmallText>Reinserisci password</SmallText>
              <Input
                variant="flushed"
                placeholder="Reinserisci nuova password"
                type="password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              <Button
                colorScheme="blue"
                disabled={code === '' || password === '' || repeatPassword === '' || password !== repeatPassword}
                onClick={handleReset}
                isLoading={loading}
              >
                Reset password
              </Button>
            </>
          )}
          {error !== '' && (
            <Text color="red" mt="3rem">
              {error}
            </Text>
          )}
          {success && (
            <>
              <Text color="green" mt="3rem">
                Password modificata con successo. A breve verrai reindirizzato alla pagina di login
              </Text>
              <Progress colorScheme="green" size="xs" isIndeterminate />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Forgot;
