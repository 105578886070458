import React, { useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import BottomMenu from '../components/BottomMenu';
import HeadingTitle from '../components/HeadingTitle';

import Faqs from '../services/faqs';

export interface IFaq {
  id: number | string;
  titolo: string;
  testo: string;
}

const FaqsList: React.FC = () => {
  const [list, setList] = useState<IFaq[]>([]);

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    const { faqs } = await Faqs.getFaqs();

    setList(faqs);
  };

  return (
    <>
      <Box p="1rem" pb="150px" minH="68vh">
        <HeadingTitle>F.A.Q.s.</HeadingTitle>
        <Accordion allowMultiple>
          {list.map((item, idx) => (
            <AccordionItem key={item.id} border={0} mb={1}>
              <AccordionButton
                bg="#1297c4"
                color="#fff"
                paddingInline={0}
                p={4}
                mb={1}
                border="hidden"
                borderTopRadius={idx === 0 ? 14 : 0}
                _hover={{ bg: '#1297c4', color: '#fff' }}
                _focus={{ outline: 0 }}
              >
                <Box flex="1" textAlign="left">
                  {item.titolo}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} bg="gray.100" whiteSpace="pre">
                {item.testo}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
      <BottomMenu />
    </>
  );
};

export default FaqsList;
