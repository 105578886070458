import { Box, Button, Input, Textarea } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import HeadingTitle from '../components/HeadingTitle';
import SmallText from '../components/SmallText';

import Tickets from '../services/tickets';
import { scrollIntoView } from '../utils';

const NewTicket = () => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [allegato, setAllegato] = useState<File>();

  const allegatiRef = useRef<HTMLInputElement>(null);
  const cameraRef = useRef<HTMLInputElement>(null);

  let history = useHistory();

  const handleNewTicket = async () => {
    setLoading(true);

    const data = new FormData();

    data.append('oggetto', title);
    data.append('testo', message);

    // FIXME: handle multiple in case

    if (allegato) {
      data.append('file', allegato, allegato.name);
    }

    // const files = allegatiRef.current?.files;

    // if (files) {
    //   for (let i = 0; i < files.length; i++) {
    //     data.append('file', files[i], files[i].name);
    //   }
    // }

    try {
      await Tickets.newTicketData(data);
      history.replace('/');
    } catch (error) {
      alert(error);
    }

    setLoading(false);
  };
  const handleAllegato = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAllegato(e.target.files[0]);
    }
  };

  return (
    <>
      <Box p="1rem" pb="250px">
        <HeadingTitle>Nuova Segnalazione</HeadingTitle>
        <Box>
          <SmallText>Oggetto:</SmallText>
          <Input
            variant="flushed"
            placeholder="Inserisci oggetto"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={scrollIntoView}
          />
        </Box>
        <Box mt="1rem">
          <SmallText>Corpo messaggio:</SmallText>
          <Textarea
            h="200px"
            variant="flushed"
            placeholder="Inserisci corpo messaggio"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={scrollIntoView}
          />
          <SmallText>Allega file:</SmallText>
          <input ref={allegatiRef} onChange={handleAllegato} type="file" name="file" id="file" style={{ display: 'none' }} />
          <input
            onChange={handleAllegato}
            ref={cameraRef}
            id="add-images"
            type="file"
            name="file"
            accept="image/*"
            // capture="camera"
            style={{ display: 'none' }}
            capture
          />
          {allegato && <p>{allegato.name.toString()}</p>}
          <Button colorScheme="blue" variant="outline" onClick={() => allegatiRef.current?.click()}>
            Aggiungi file
          </Button>
          <Button colorScheme="blue" variant="outline" ml={3} onClick={() => cameraRef.current?.click()}>
            Scatta una foto
          </Button>
        </Box>
        <Box pt="1rem" textAlign="center">
          <Button colorScheme="blue" width="100%" onClick={handleNewTicket} disabled={!title || !message} isLoading={loading}>
            INVIA SEGNALAZIONE
          </Button>
        </Box>
      </Box>
      <BottomMenu />
    </>
  );
};

export default NewTicket;
