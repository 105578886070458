import api from './api';

const getTickets = async (archived: boolean = false) => {
  const url = '/api/ticket' + (archived ? '/all' : '');
  const res = await api.get(url);

  return res.data;
};
const getTicket = async (id: string | number) => {
  const res = await api.get(`/api/ticket/${id}`);

  return res.data;
};

const newTicket = async (oggetto: string, testo: string) => {
  if (!oggetto || !testo) {
    return;
  }
  const res = await api.post('/api/ticket', {
    oggetto,
    testo,
  });

  return res.data;
};

const newTicketData = async (data: FormData) => {
  const res = await api.post('/api/ticket', data);

  return res.data;
};

const addReply = async (id: string, data: FormData) => {
  const res = await api.post(`/api/ticket/${id}`, data);

  return res.data;
};

const getFile = async (fileId: string) => {
  const res = await api.get(`/api/getfile?f=${fileId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });

  return res;
};

const getProfile = async () => {
  const res = await api.get(`/api/profile`);

  return res.data;
};

const Tickets = {
  getTickets,
  getTicket,
  addReply,
  newTicket,
  newTicketData,
  getFile,
  getProfile,
};

export default Tickets;
