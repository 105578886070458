import { Box, ChakraComponent } from '@chakra-ui/react';
import React from 'react';

const BottomBox: ChakraComponent<'div'> = (props) => {
  return (
    <Box position="relative" bottom="0" width="100%" {...props}>
      {props.children}
    </Box>
  );
};

export default BottomBox;
