import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import NotApproved from './NotApproved';

const PrivateRoute = ({ children, ...rest }: any) => {
  const { isLogged, isLoading, pending } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoading ? (
          isLogged ? (
            pending ? (
              <NotApproved />
            ) : (
              children
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <>
            <p>caricamento in corso</p>
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
