import React, { useState } from 'react';
import Auth from '../services/auth';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import {
  Text,
  Box,
  Button,
  Input,
  Stack,
  FormErrorMessage,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Progress,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { BiShow, BiHide } from 'react-icons/bi';
import * as Yup from 'yup';
import SmallText from '../components/SmallText';
import HeadingTitle from '../components/HeadingTitle';
import { useHistory } from 'react-router-dom';

// Yup.addMethod(Yup.string, 'noWhitespace', function noWhiteSpace() {
//   return this.transform((value, originalValue) => (/\s/.test(originalValue) ? undefined : value));
// });

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Campo obbligatorio'),
  surname: Yup.string().required('Campo obbligatorio'),
  email: Yup.string().email('Email non valida').required('Email obbligatoria'),
  username: Yup.string().min(4, 'Minimo 4 lettere').required('Campo obbligatorio'),
  password: Yup.string().min(8, 'Lunghezza minima 8 caratteri').required('Campo obbligatorio'),
  p_iva: Yup.string()
    .required('Campo Obbligatorio')
    .matches(/^(\S+$)/, 'Questo campo non può contenere spazi'),
  ragione_sociale: Yup.string().required('Campo obbligatorio'),
  num_autorizzazione: Yup.string().required('Campo obbligatorio'),
  doc_autorizzazione: Yup.mixed().required('Campo obbligatorio'),
});

const Register: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  let history = useHistory();

  const handleSubmit = async (values: FormikValues, helpers: FormikHelpers<any>) => {
    console.log('submitting');
    const data = new FormData();

    const keys = Object.keys(values);

    keys.forEach((k) => {
      if (k === 'doc_autorizzazione') {
        data.append(k, values[k], values[k].name);
      } else {
        data.append(k, values[k]);
      }
    });

    try {
      await Auth.register(data);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        history.replace('/');
      }, 3000);
    } catch (errors: any) {
      console.log(errors);
      const { data } = errors.response;

      Object.keys(data).forEach((k) => {
        helpers.setFieldError(k, data[k]);
      });
    }
  };

  return (
    <>
      <Modal isOpen={success} onClose={() => console.log('closing')} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={50} textAlign="center">
            <Stack spacing={3}>
              <Text fontWeight="bold">Account creato con successo</Text>
              <Text>A breve verrai reindirizzato alla pagina di login</Text>
              <Progress size="xs" isIndeterminate />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box p="1rem">
        <HeadingTitle>Registrati</HeadingTitle>
        <Formik
          validateOnBlur={true}
          // initialValues={{
          //   name: 'test3',
          //   surname: 'test3',
          //   email: 'test3@test.it',
          //   username: 'test3',
          //   password: '12345678',
          //   p_iva: '1234567898',
          //   ragione_sociale: 'ragione sociale',
          //   num_autorizzazione: '123213',
          //   doc_autorizzazione: null,
          // }}
          initialValues={{
            name: '',
            surname: '',
            email: '',
            username: '',
            password: '',
            p_iva: '',
            ragione_sociale: '',
            num_autorizzazione: '',
            doc_autorizzazione: null,
          }}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
          // onSubmit={(values) => {
          //   console.log(values);
          // }}
        >
          {({ errors, touched, values, handleChange, handleBlur, setFieldValue, isValid, dirty, isSubmitting }) => {
            const isInvalid = (prop: any) => {
              const t: any = { ...touched };
              const e: any = { ...errors };
              return t[prop] && e[prop] !== undefined;
            };
            return (
              <Form>
                <Stack>
                  <FormControl isInvalid={isInvalid('name')}>
                    <SmallText>Nome</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci nome"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange('name')}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('surname')}>
                    <SmallText>Cognome</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci cognome"
                      name="surname"
                      value={values.surname}
                      onBlur={handleBlur}
                      onChange={handleChange('surname')}
                    />
                    <FormErrorMessage>{errors.surname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('email')}>
                    <SmallText>Email</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci email"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange('email')}
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('username')}>
                    <SmallText>Username</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci username"
                      name="username"
                      value={values.username}
                      onBlur={handleBlur}
                      onChange={handleChange('username')}
                    />
                    <FormErrorMessage>{errors.username}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('password')}>
                    <SmallText>Password</SmallText>
                    <InputGroup>
                      <Input
                        variant="flushed"
                        placeholder="Inserisci password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange('password')}
                      />
                      <InputRightElement w="4.5rem">
                        <Button variant="link" onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Icon as={BiHide} /> : <Icon as={BiShow} />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('ragione_sociale')}>
                    <SmallText>Ragione Sociale</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci ragione sociale"
                      name="ragione_sociale"
                      value={values.ragione_sociale}
                      onBlur={handleBlur}
                      onChange={handleChange('ragione_sociale')}
                    />
                    <FormErrorMessage>{errors.ragione_sociale}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('p_iva')}>
                    <SmallText>Partita IVA / Codice Fiscale</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci Partita IVA"
                      name="p_iva"
                      value={values.p_iva}
                      onBlur={handleBlur}
                      onChange={handleChange('p_iva')}
                    />
                    <FormErrorMessage>{errors.p_iva}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('num_autorizzazione')}>
                    <SmallText>Codice allevamento</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci numero di autorizzazione"
                      name="num_autorizzazione"
                      value={values.num_autorizzazione}
                      onBlur={handleBlur}
                      onChange={handleChange('num_autorizzazione')}
                    />
                    <FormErrorMessage>{errors.num_autorizzazione}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isInvalid('doc_autorizzazione')}>
                    <SmallText>Copia documento</SmallText>
                    <Input
                      variant="flushed"
                      placeholder="Inserisci documento"
                      name="doc_autorizzazione"
                      type="file"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.currentTarget.files) {
                          setFieldValue('doc_autorizzazione', e.currentTarget.files[0]);
                        }
                      }}
                    />
                    <FormErrorMessage>{errors.num_autorizzazione}</FormErrorMessage>
                  </FormControl>
                  <Button type="submit" colorScheme="blue" width="100%" disabled={!(isValid && dirty)} isLoading={isSubmitting}>
                    Registrati
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default Register;
