import React, { useContext, useEffect, useState } from 'react';
import Auth from '../services/auth';
import TokenService from '../services/token';

interface IAuthContextType {
  isLoading: boolean;
  isLogged: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
  pending: boolean;
  setPending: (val: boolean) => void;
}

export const AuthContext = React.createContext<IAuthContextType>({
  isLoading: true,
  isLogged: false,
  login: () => null,
  logout: () => null,
  pending: false,
  setPending: () => null,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = () => {
    const token = TokenService.getAccessToken();
    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    setIsLoading(false);
  };

  const login = async (username: string, password: string) => {
    try {
      await Auth.login(username, password);
      setIsLogged(true);
      return {
        status: 'success',
      };
    } catch (error) {
      setIsLogged(false);
      return {
        status: 'error',
        message: 'Credenziali errate',
      };
    }
  };

  const logout = async () => {
    Auth.logout();
    setIsLogged(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isLogged,
        login,
        logout,
        pending,
        setPending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
