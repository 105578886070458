import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Input, Stack, Text, Link } from '@chakra-ui/react';
import { NavLink, useHistory } from 'react-router-dom';
import BottomBox from '../components/BottomBox';
import SmallText from '../components/SmallText';
import HeadingTitle from '../components/HeadingTitle';
import { useAuth } from '../contexts/AuthContext';
import TokenService from '../services/token';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [remember, setRemember] = useState(false);

  let history = useHistory();

  const { login } = useAuth();

  const handleLogin = async () => {
    TokenService.saveCredentials(remember, email);
    const res: any = await login(email, password);

    if (res.status === 'error') {
      setError(res.message);
      return;
    }
    setError('');
    history.replace('/');
  };

  useEffect(() => {
    const { email, remember } = TokenService.getCredentials();
    if (remember) {
      setRemember(true);
      if (email) {
        setEmail(email);
      }
    }
  }, []);

  return (
    <>
      <Box p="1rem">
        <HeadingTitle>Login</HeadingTitle>
        <Stack mb={30}>
          <SmallText>Email</SmallText>
          <Input variant="flushed" placeholder="Inserisci email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Stack>
        <Stack>
          <SmallText>Password</SmallText>
          <Input
            variant="flushed"
            placeholder="Inserisci password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />
        </Stack>
        <Checkbox mt="1rem" isChecked={remember} onChange={(e) => setRemember(e.target.checked)}>
          Ricorda credenziali
        </Checkbox>
        {error !== '' && (
          <Text color="red" mt="3rem">
            {error}
          </Text>
        )}
        <Stack mt={10} textAlign="right">
          <NavLink exact to="/login/recover">
            <SmallText>Recupera password?</SmallText>
          </NavLink>
        </Stack>
      </Box>
      <BottomBox p="1rem" textAlign="center">
        <Button colorScheme="blue" width="100%" onClick={handleLogin} isDisabled={!email || !password}>
          Login
        </Button>
        <Text mt="0.5em">
          Non hai un Account?{' '}
          <Link as={NavLink} exact to="/register" color="blue.500">
            Registrati ora
          </Link>
        </Text>
      </BottomBox>
    </>
  );
};

export default Login;
