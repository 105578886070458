import React from 'react';
import { Switch, Route } from 'react-router-dom/';
// import axios from 'axios';
import './App.css';
import Header from './components/Header';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import Ticket from './pages/Ticket';
import NewTicket from './pages/NewTicket';
import Profile from './pages/Profile';
import NewsList from './pages/NewsList';
import NewsComponent from './pages/NewsComponent';
import PrivateRoute from './components/PrivateRoute';
import Forgot from './pages/Forgot';
import { Box, Center } from '@chakra-ui/react';
import FaqsList from './pages/FaqsList';

// axios.defaults.baseURL = 'http://localhost:3001';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.get['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

function App() {
  return (
    <>
      <Header />
      <Center>
        {/* <Box w="1024px" bg="#fff" border="1px solid #e3e3e3"> */}
        <Box w="1024px" bg="#fff" border="1px solid #e3e3e3">
          <Switch>
            <PrivateRoute exact path={['/', '/home']}>
              <Home />
            </PrivateRoute>
            <Route exact path="/login/recover">
              <Forgot />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <PrivateRoute exact path="/newticket">
              <NewTicket />
            </PrivateRoute>
            <PrivateRoute exact path="/ticket/:id">
              <Ticket />
            </PrivateRoute>
            <PrivateRoute exact path="/news/:id">
              <NewsComponent />
            </PrivateRoute>
            <PrivateRoute exact path="/news">
              <NewsList />
            </PrivateRoute>
            <PrivateRoute exact path="/faqs">
              <FaqsList />
            </PrivateRoute>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <PrivateRoute exact path="/all">
              <Home archived={true} />
            </PrivateRoute>
          </Switch>
        </Box>
      </Center>
    </>
  );
}
export default App;
