import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import HeadingTitle from '../components/HeadingTitle';
import { useAuth } from '../contexts/AuthContext';

// import { ITicket, lista } from '../mockdata';
import Tickets from '../services/tickets';
import { formatDate } from '../utils';

interface IHomeProps {
  archived?: boolean;
}
export interface IDocumenti {
  id: string;
  filename: string;
}
export interface ITicketRisposta {
  testo: string;
  data: Date;
  added_at: Date;
  user: string;
  name: string;
  documenti: IDocumenti[];
  id_utente: string;
}
export interface ITicket {
  id: number;
  oggetto: string;
  testo?: string;
  data: Date;
  creato_il: Date;
  // risposte?: ITicketRisposta[];
  archived: boolean;
  risposte?: ITicketRisposta[];
  status: string;
  id_utente: string;
  documenti: IDocumenti[];
}

const Home: React.FC<IHomeProps> = ({ archived }) => {
  const [list, setList] = useState<ITicket[]>([]);
  const { setPending } = useAuth();
  // useEffect(() => {
  //   let temp: ITicket[] = [];
  //   if (!archived) {
  //     temp = lista.filter((l) => l.archived === false);
  //   } else {
  //     temp = [...lista];
  //   }

  //   setList(temp);
  // }, [archived]);

  useEffect(() => {
    getTickets(archived);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived]);

  const getTickets = async (archived = false) => {
    const t = await Tickets.getTickets(archived);

    if (t.unverified) {
      setPending(true);
      return;
    }
    setPending(false);

    setList(t.tickets);
  };

  return (
    <>
      <Box p="1rem" pb="150px" minH="68vh">
        <HeadingTitle>{archived ? 'Storico' : 'Ultime'} segnalazioni</HeadingTitle>
        {list.length === 0 && <Text>Non ci sono segnalazioni aperte.</Text>}
        {list.map((l) => {
          // const ticketDate = new Date(l.creato_il);

          const ticketDate = formatDate(l.creato_il, 'date');
          const { id } = l;

          return (
            <NavLink key={`l-${id}`} exact to={`/ticket/${id}`}>
              <Box borderRadius={14} w="100%" color="white" bg="#1297c4" p="1rem" marginY={'1rem'}>
                <Text>{l.oggetto}</Text>
                <Text align="right" fontSize="10">
                  {/* {ticketDate.toLocaleDateString()} */}
                  {ticketDate}
                </Text>
              </Box>
            </NavLink>
          );
        })}
      </Box>
      <BottomMenu />
    </>
  );
};

export default Home;
