import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import HeadingTitle from '../components/HeadingTitle';

import News from '../services/news';

export interface INew {
  id: number | string;
  oggetto: string;
  creato_il: Date;
  tipo: string;
}

const NewsList: React.FC = () => {
  const [list, setList] = useState<INew[]>([]);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    const { news } = await News.getNews();

    setList(news);
  };

  return (
    <>
      <Box p="1rem" pb="150px" minH="68vh">
        <HeadingTitle>News e Avvisi</HeadingTitle>
        {list.map((l, i) => {
          const date = new Date(l.creato_il);

          return (
            <NavLink key={`l-${i}`} exact to={`/news/${l.id}`}>
              <Box borderRadius={14} w="100%" color="white" bg={l.tipo === '1' ? '#1297c4' : 'red.400'} p="1rem" marginY={'1rem'}>
                <Text fontWeight="bold" mb="0.5rem">
                  {l.tipo === '1' ? 'News' : 'Avviso'}:
                </Text>
                <Text>{l.oggetto}</Text>
                <Text align="right" fontSize="10">
                  {date.toDateString()}
                </Text>
              </Box>
            </NavLink>
          );
        })}
      </Box>
      <BottomMenu />
    </>
  );
};

export default NewsList;
