import { Box, Button, Link, Text, Textarea } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SmallText from '../components/SmallText';
import Tickets from '../services/tickets';
import { formatDate, scrollIntoView } from '../utils';
import { ITicket } from './Home';

interface ITicketProps {
  id: string;
}

const Ticket = () => {
  const [risposta, setRisposta] = useState('');
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<ITicket>();
  const [isClosed, setIsClosed] = useState(false);
  const [allegato, setAllegato] = useState<File>();

  const allegatiRef = useRef<HTMLInputElement>(null);
  const cameraRef = useRef<HTMLInputElement>(null);

  const { id } = useParams<ITicketProps>();

  useEffect(() => {
    getTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTicket = async () => {
    const t = await Tickets.getTicket(id);

    const ticket: ITicket = t.ticket;

    ticket.risposte = t.risposte;
    if (ticket.status === '3') {
      setIsClosed(true);
    }
    setTicket(ticket);
  };

  const handleRisposta = async () => {
    setLoading(true);

    const data = new FormData();

    data.append('testo', risposta);

    if (allegato) {
      data.append('file', allegato, allegato.name);
    }

    try {
      await Tickets.addReply(id, data);
      // if (allegatiRef.current) {
      //   allegatiRef.current.files = null;
      //   allegatiRef.current.value = '';
      // }
      getTicket();
    } catch (error) {
      alert('error');
    }
    setLoading(false);
    setRisposta('');
  };

  const getFile = async (id: string, filename: string) => {
    try {
      const res = await Tickets.getFile(id);
      const type = res.headers['content-type'];

      const blob = new Blob([res.data], { type });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      alert('error');
    }
  };

  const handleAllegato = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAllegato(e.target.files[0]);
    }
  };

  return (
    <Box p="1rem">
      {ticket ? (
        <>
          <Box mb="2em">
            <Text color="#114e64" fontWeight="bold">
              DATI SEGNALAZIONE
            </Text>
          </Box>
          <Box mb="1rem">
            <Text fontWeight="bold">Oggetto:</Text>
            <Text>{ticket.oggetto}</Text>
          </Box>
          <Box mb="1rem">
            <Text fontWeight="bold">Testo:</Text>
            <Text>{ticket.testo}</Text>
          </Box>
          {ticket.documenti && (
            <Box mb="1rem">
              <Text fontWeight="bold">Allegati:</Text>
              {ticket.documenti.map((d) => (
                // <Link key={d.id} color="teal.500" fontSize="13px" href={`http://localhost:3001/api/getfile?f=${d.id}`} isExternal>
                <Link key={d.id} color="teal.500" fontSize="13px" onClick={() => getFile(d.id, d.filename)} isExternal>
                  {d.filename}
                </Link>
              ))}
            </Box>
          )}
          <Box>
            <Text fontWeight="bold">Stato ticket:</Text>
            <Text color={isClosed ? 'red.500' : 'green.500'}>{isClosed ? 'Chiuso' : 'Aperto'}</Text>
          </Box>
          <Box mt="0.5rem">
            <Text fontSize="12px" textAlign="right">
              {formatDate(ticket.creato_il)}
            </Text>
          </Box>
          <Box mt="1rem">
            {ticket.risposte &&
              ticket.risposte.map((r, i) => {
                const bgColor = ticket.id_utente === r.id_utente ? 'blue.100' : 'gray.200'; // #dedfdf

                return (
                  <Box key={`r-${i}`} bg={bgColor} borderRadius="15px" p="1rem" mb="1rem">
                    <Text fontWeight="bold">{r.name}:</Text>
                    <Text mt="0.5rem">{r.testo}</Text>
                    {r.documenti.length > 0 && (
                      <Text fontWeight="bold" fontSize="12" mt="1.5rem">
                        ALLEGATI
                      </Text>
                    )}
                    {r.documenti &&
                      r.documenti.map((d) => (
                        // <Link key={d.id} color="teal.500" fontSize="13px" href={`http://localhost:3001/api/getfile?f=${d.id}`} isExternal>
                        <Link key={d.id} color="teal.500" fontSize="13px" onClick={() => getFile(d.id, d.filename)} isExternal>
                          {d.filename}
                        </Link>
                      ))}
                    <Text mt="0.5rem" fontSize="10px" textAlign="right">
                      {formatDate(r.added_at)}
                    </Text>
                  </Box>
                );
              })}
          </Box>
          {!isClosed && (
            <>
              <Box>
                <SmallText>Rispondi:</SmallText>
                <Textarea
                  placeholder="Inserisci corpo messaggio"
                  value={risposta}
                  onChange={(e) => setRisposta(e.target.value)}
                  onFocus={scrollIntoView}
                />
                <SmallText>Allega:</SmallText>
                <input ref={allegatiRef} onChange={handleAllegato} type="file" name="file" id="file" style={{ display: 'none' }} />
                <input
                  onChange={handleAllegato}
                  ref={cameraRef}
                  id="add-images"
                  type="file"
                  accept="image/*"
                  // capture="camera"
                  style={{ display: 'none' }}
                  multiple
                />
                {allegato && <p>{allegato.name.toString()}</p>}
                <Button colorScheme="blue" variant="outline" onClick={() => allegatiRef.current?.click()}>
                  Aggiungi file
                </Button>
                <Button colorScheme="blue" variant="outline" ml={3} onClick={() => cameraRef.current?.click()}>
                  Scatta una foto
                </Button>
              </Box>
              <Box pt="1.5rem">
                <Button colorScheme="blue" width="100%" onClick={handleRisposta} isLoading={loading} disabled={risposta === ''}>
                  INVIA RISPOSTA
                </Button>
              </Box>
            </>
          )}
        </>
      ) : (
        <p>Non Esiste</p>
      )}
    </Box>
  );
};

export default Ticket;
