import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import BottomMenu from './BottomMenu';

const NotApproved = () => {
  return (
    <>
      <Box p="1rem" pb="150px" minH="68vh">
        <Center h="30vh">Non sei ancora stato abilitato alla creazione di ticket.</Center>
      </Box>
      <BottomMenu />
    </>
  );
};

export default NotApproved;
