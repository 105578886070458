import { ComponentWithAs, Heading } from '@chakra-ui/react';
import React from 'react';

const HeadingTitle: ComponentWithAs<'h2'> = ({ children }) => {
  return (
    <Heading mb={30} color="#1297c4">
      {children}
    </Heading>
  );
};

export default HeadingTitle;
