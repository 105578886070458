import api from './api';

const getFaqs = async () => {
  const res = await api.get('/api/faqs');

  return res.data;
};

const Faqs = {
  getFaqs,
};

export default Faqs;
