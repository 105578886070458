import React from 'react';
import { ComponentWithAs, Text } from '@chakra-ui/react';

const SmallText: ComponentWithAs<'p'> = ({ children }) => {
  return (
    <Text color="#114e64" fontFamily="Open Sans" fontWeight="bold" fontSize={14}>
      {children}
    </Text>
  );
};

export default SmallText;
