import React, { useEffect, useState } from 'react';
import { Box, Flex, Icon, Link } from '@chakra-ui/react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import logo from '../assets/logo-sisim.png';

const Header = () => {
  const [backButton, setBackButton] = useState(false);
  let { pathname } = useLocation();
  let history = useHistory();

  useEffect(() => {
    const paths = ['/', '/login', '/home'];
    setBackButton(paths.includes(pathname));
  }, [pathname]);

  return (
    <>
      <Flex bg="#1297c4" h={150} width="100%" alignItems="center" justifyContent="space-between">
        <Box w="200px">
          {!backButton && <Icon as={BsArrowLeftShort} w={16} h={16} color="white" cursor="pointer" onClick={() => history.goBack()} />}
        </Box>
        <Box>
          <Link as={NavLink} to="/">
            <img src={logo} alt="logo-sisim" style={{ width: 200 }} />
          </Link>
        </Box>
        <Box w="200px"></Box>
      </Flex>
    </>
  );
};

export default Header;
