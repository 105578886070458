import { DateTime } from 'luxon';

export const scrollIntoView = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  e.target.scrollIntoView();
};

export const formatDate = (date: Date, opt?: 'date' | 'full') => {
  const d = DateTime.fromSQL(date.toString()).setLocale('it-IT');

  if (opt === 'date') {
    return d.toLocaleString(DateTime.DATE_MED);
  }
  return d.toLocaleString(DateTime.DATETIME_SHORT);
};
