import { Box, Avatar, Wrap, WrapItem, Center, Text, Stack, VStack, InputGroup, InputLeftAddon, Input, Button } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import SmallText from '../components/SmallText';
import { AuthContext } from '../contexts/AuthContext';
import Tickets from '../services/tickets';

const Profile = () => {
  const { logout } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  let history = useHistory();

  const handleLogout = () => {
    logout();
    history.replace('/');
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    const res = await Tickets.getProfile();

    const { name, surname, email, username } = res.user;

    setName(name);
    setSurname(surname);
    setEmail(email);
    setUsername(username);
  };

  return (
    <>
      <Box p="1rem" pb="120px" minH="68vh">
        <Center mt="1.5rem">
          <VStack>
            <Wrap>
              <WrapItem>
                <Avatar size="2xl" name={`${name} ${surname}`} />
              </WrapItem>
            </Wrap>
            <Text fontSize="25px" fontWeight="bold" color="#114e64">{`${name} ${surname}`}</Text>
          </VStack>
        </Center>
        <Box mt="2rem">
          <SmallText>Anagrafica</SmallText>
          <Stack spacing={4} mt="1rem">
            <InputGroup>
              <InputLeftAddon children="Nome" bg="white" border="" minW="120px" color="#1297c4" />
              <Input variant="flushed" placeholder="nome" value={name} onChange={() => {}} />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children="Cognome" bg="white" border="" minW="120px" color="#1297c4" />
              <Input variant="flushed" placeholder="cognome" value={surname} onChange={() => {}} />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children="Email" bg="white" border="" minW="120px" color="#1297c4" />
              <Input variant="flushed" placeholder="email" value={email} onChange={() => {}} disabled />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children="Username" bg="white" border="" minW="120px" color="#1297c4" />
              <Input variant="flushed" placeholder="username" value={username} onChange={() => {}} />
            </InputGroup>
          </Stack>
        </Box>
        <Center mt="3rem">
          <Button colorScheme="blue" width="100%" onClick={handleLogout}>
            Logout
          </Button>
        </Center>
      </Box>
      <BottomMenu />
    </>
  );
};

export default Profile;
