import axios from 'axios';
import TokenService from './token';

//development
// const url = 'http://localhost:3001/';

//production
// const url = 'https://sisim-backend.considera.it/';

const url = process.env.REACT_APP_URL;

const instance = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    console.log('using token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/login' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post('/auth/refresh_token', {
            refresh_token: TokenService.getRefreshToken(),
          });

          const { access_token, refresh_token } = rs.data;
          TokenService.setAccessToken(access_token);
          TokenService.setRefreshToken(refresh_token);
          return instance(originalConfig);
        } catch (_error) {
          window.location.href = '/login';
          return Promise.reject(_error);
          // return;
        }
      }
    }
    return Promise.reject(err);
    // return;
  }
);

export default instance;
