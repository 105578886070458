const getAccessToken = () => {
  return localStorage.getItem('access_token');
};
const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};
const setAccessToken = (token: string) => {
  localStorage.setItem('access_token', token);
};
const setRefreshToken = (token: string) => {
  localStorage.setItem('refresh_token', token);
};
const removeTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

const getCredentials = () => {
  const email = localStorage.getItem('email');
  const r = localStorage.getItem('remember');

  return {
    email,
    remember: r === '1' ? true : false,
  };
};

const saveCredentials = (remember: boolean, email: string) => {
  localStorage.setItem('remember', remember ? '1' : '0');
  localStorage.setItem('email', remember ? email : '');
};

const TokenService = {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeTokens,
  getCredentials,
  saveCredentials,
};
export default TokenService;
